import { GlobalRoutePaths } from '@app/routes';
import { TrackingEvents, TrackingOrigins } from '@app/tracking';
import { VERIFY_PHONE_CODE_ERROR_CODES } from '@commons/services/constants/ErrorCodes';
import { Text } from '@components/Fenix/Typography';
import { FormBody, FormFooter } from '@components/Form';
import { Layout } from '@components/Layout';
import { PageTitle } from '@components/Shell';
import { useSendPhoneCode } from '@hooks/use-send-phone-code';
import { useVerifyPhoneCode } from '@hooks/use-verify-phone-code';
import { Button } from '@pedidosya/web-fenix/atoms';
import { useAuth } from '@providers/AuthProvider';
import { useIntl } from '@providers/IntlProvider';
import { useTracker } from '@providers/TrackerProvider';
import { useToast } from '@providers/ToastProvider';
import { useDevice } from '@providers/DeviceProvider';
import React, { useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import Codes from './components/Codes';
import HelpTooltip from './components/HelpTooltip';
import ResendCodeButton from './components/ResendCodeButton';
import { CODES_LENGTH } from './constants';
import pvMessages from './../../messages';
import messages from './messages';
import { ChannelPhoneConfig, ChannelsLabels } from '@app/types';

const FooterButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.space('spacing-04')};
`;

type CodeValidatorProps = {
  phoneNumber: string;
  phoneValidationType: string;
  channels: ChannelPhoneConfig[];
  onSuccess: (token?: string) => void;
  onBack: () => void;
  origin: string;
};

function CodeValidator({
  phoneNumber,
  phoneValidationType,
  channels,
  onSuccess,
  onBack,
  origin,
}: CodeValidatorProps): JSX.Element {
  const { isAuthenticated, user } = useAuth();
  const { formatMessage } = useIntl();
  const resendRef = useRef(0);
  const toast = useToast();
  const tracker = useTracker();
  const phoneValidationOrigin = origin || TrackingOrigins.notSet;
  const { isDesktop } = useDevice();
  const [resendCodeButtons, setResendCodeButtons] = useState(
    getResendCodeButtons(phoneValidationType),
  );
  const [countTimeOver, setCountTimeOver] = useState(0);
  const [showAlternativeType, setShowAlternativeType] = useState(false);
  const [showCodeInputError, setCodeInputError] = useState(null);

  const { mutateAsync: sendPhoneCodeMutateAsync, isLoading: isLoadingSendPhoneCode } =
    useSendPhoneCode();
  const { mutateAsync: verifyPhoneCodeMutateAsync, isLoading: isLoadingVerifyPhoneCode } =
    useVerifyPhoneCode();

  const handleTimeOver = () => {
    setCountTimeOver(countTimeOver === 2 ? 1 : countTimeOver + 1);
    setShowAlternativeType(!!resendCodeButtons.alternative);
  };

  function getResendCodeButtons(type: string) {
    const upperCaseType = type.toUpperCase();
    const altButton = channels.find(({ channel }) => channel !== upperCaseType);
    const alternative = altButton && {
      type: altButton.channel,
      message: pvMessages[altButton.channel as ChannelsLabels],
    };

    return { main: { type: upperCaseType }, alternative };
  }

  const redirectToHelp = () => {
    window.location.href = GlobalRoutePaths.CustomerService;
  };

  const handleResend = async (type: string) => {
    setShowAlternativeType(false);

    tracker.track(TrackingEvents.PhoneValidationResent, {
      phoneValidationOrigin,
      phoneValidationType: type,
    });

    resendRef.current += 1;

    const { error } = await sendPhoneCodeMutateAsync({
      type,
      phoneNumber,
      userId: isAuthenticated ? user.id : null,
    });

    if (error) {
      toast.error(`${formatMessage(messages.sendCodeErrorMessage)}`);
    }
  };

  const changePhoneValidationType = (type: string) => {
    const newResendCodeButtons = getResendCodeButtons(type);
    setResendCodeButtons(newResendCodeButtons);
    handleResend(type);
  };

  const verify = async (code: string) => {
    tracker.track(TrackingEvents.PhoneValidationInsertCode, {
      phoneValidationOrigin,
      phoneValidationType: resendCodeButtons.main.type,
    });

    resendRef.current += 1;

    const { data, error } = await verifyPhoneCodeMutateAsync({
      phoneNumber,
      code,
      userId: isAuthenticated ? user.id : null,
    });

    if (error) {
      const errorMessage = `${formatMessage(messages[error?.code] ?? messages.genericError)}`;

      tracker.track(TrackingEvents.PhoneValidationFailed, {
        phoneValidationOrigin,
        phoneValidationType: resendCodeButtons.main.type,
        phoneValidationErrorMessage: errorMessage,
      });

      switch (error?.code) {
        case VERIFY_PHONE_CODE_ERROR_CODES.ALREADY_VALIDATED_BY_OTHER_USER:
          toast.error(errorMessage, formatMessage(messages.errorToastActionLabel), redirectToHelp);
          break;
        case VERIFY_PHONE_CODE_ERROR_CODES.VALIDATION_FAILED:
          setCodeInputError(errorMessage);
          break;
        default:
          toast.error(errorMessage);
          break;
      }
    }

    if (data) {
      const { validation_token: token } = data;
      if (token || isAuthenticated) {
        tracker.track(TrackingEvents.PhoneValidationCompleted, {
          phoneValidationOrigin,
          phoneValidationType: resendCodeButtons.main.type,
        });
        toast.success(`${formatMessage(messages.successMessage)}`);
        onSuccess(token);
      } else {
        toast.error(`${formatMessage(messages.genericError)}`);
      }
    }
  };

  return (
    <Layout isLoading={isLoadingVerifyPhoneCode || isLoadingSendPhoneCode}>
      <PageTitle
        title={formatMessage(messages.title)}
        onBack={onBack}
        withMobileMarginBottom={false}
      />
      <Text
        size="medium"
        color="tertiary"
        style={{ margin: isDesktop ? '0 16px 16px' : '0px 16px' }}
      >
        <FormattedMessage
          {...messages.subtitle}
          values={{
            phoneNumber: <strong>{phoneNumber}</strong>,
          }}
        />
      </Text>
      <FormBody>
        <Codes
          key={`codes-${resendRef.current}`}
          length={CODES_LENGTH}
          onCompleted={verify}
          showCodeInputError={showCodeInputError}
          setCodeInputError={setCodeInputError}
        />
        <ResendCodeButton
          phoneValidationType={resendCodeButtons.main.type}
          onClick={() => handleResend(resendCodeButtons.main.type)}
          onTimeOver={handleTimeOver}
        />
      </FormBody>
      {showAlternativeType && (
        <FormFooter hideBorderTop>
          <FooterButtonsContainer>
            <HelpTooltip show={countTimeOver === 2} />
            <Text size="small" color="tertiary">
              {formatMessage(messages.sendCodeQuestion)}
            </Text>
            <Button
              label={formatMessage(resendCodeButtons.alternative?.message)}
              hierarchy="tertiary"
              size="medium"
              onClick={() => changePhoneValidationType(resendCodeButtons.alternative?.type)}
            />
          </FooterButtonsContainer>
        </FormFooter>
      )}
    </Layout>
  );
}

export { CodeValidator };
