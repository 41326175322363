import { RoutePaths } from '@app/routes';
import { TrackingEvents, TrackingOrigins } from '@app/tracking';
import { Button } from '@pedidosya/web-fenix/atoms';
import FenixIcon from '@pedidosya/web-fenix/icons/FenixIcon';
import { CardContainer } from '@pedidosya/web-fenix/molecules';
import fenixTheme from '@pedidosya/web-fenix/theme';
import {
  EditPhoneConfirmationProvider,
  useEditPhoneConfirmationModal,
} from '@components/EditPhoneConfirmationModal';
import { ItemSection } from '@components/Fenix/ItemSection';
import { Layout } from '@components/Layout';
import OverlayLoader from '@components/OverlayLoader';
import { PageTitle } from '@components/Shell';
import { useFlagVariations } from '@hooks/useFlagVariations';
import { useUserValidations } from '@hooks/use-user-validations';
import { useDevice } from '@providers/DeviceProvider';
import { useIntl } from '@providers/IntlProvider';
import { useTracker } from '@providers/TrackerProvider';
import * as React from 'react';
import { MessageDescriptor } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { PersonalInformationSkeleton } from './skeleton';
import messages from './messages';

import { EMAIL, PHONE, VALIDATED_STATUS } from './constants';
import { Validations } from '@app/types';

function getActionMessageDescriptor({ phoneNumber = '', verified = false }): MessageDescriptor {
  if (verified) {
    return messages.phoneNumberEditButtonLabel;
  }

  if (!!phoneNumber) {
    return messages.phoneNumberVerifyButtonLabel;
  }

  return messages.phoneNumberAddButtonLabel;
}

const Card = styled(CardContainer)<{ isDesktop?: boolean }>`
  width: auto;
  margin: ${({ isDesktop }) => (isDesktop ? '12px 16px' : '0')};
`;

const VerifiableFIeld = styled.div`
  display: flex;
  align-items: center;
`;

const VerifiedIcon = styled.div`
  align-items: center;
  display: flex;
  margin-right: 4px;
`;

const isValidated = (status: string): boolean => {
  return status === VALIDATED_STATUS;
};

function PersonalDataItem(): JSX.Element {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const tracker = useTracker();

  const handlePersonalDataPress = (): void => {
    tracker.track(TrackingEvents.PersonalInfoScreenClicked);
    navigate(RoutePaths.PersonalData);
  };

  return (
    <ItemSection
      label={formatMessage(messages.personalDataLabel)}
      caption={formatMessage(messages.personalDataCaptionLabel)}
      onClick={handlePersonalDataPress}
      action={<FenixIcon token="chevron-right" color="icon-color-primary" size="medium" />}
    />
  );
}

type PhoneNumberProps = {
  phoneVerified: boolean;
  mobile: string;
};

function PhoneNumber({ phoneVerified, mobile }: PhoneNumberProps): JSX.Element {
  return (
    <VerifiableFIeld>
      {phoneVerified && (
        <VerifiedIcon>
          <FenixIcon token="circle-check" color="icon-color-tertiary" size="small" />
        </VerifiedIcon>
      )}
      {mobile}
    </VerifiableFIeld>
  );
}

type PhoneItemProps = {
  mobile: Validations;
};

function PhoneItem({ mobile }: PhoneItemProps): JSX.Element {
  const { formatMessage } = useIntl();
  const { open: openEditPhoneConfirmationModal } = useEditPhoneConfirmationModal();
  const { isPhoneValidationEnabled } = useFlagVariations();
  const navigate = useNavigate();
  const phoneVerified = isValidated(mobile?.status);

  const handleActionPress = (): void => {
    if (isValidated(mobile?.status)) {
      openEditPhoneConfirmationModal();
    } else {
      navigate(`${RoutePaths.PhoneValidation}?origin=${TrackingOrigins.phoneEdit}`);
    }
  };

  const hasPhoneNumber = !!mobile;

  return (
    <ItemSection
      label={formatMessage(messages.phoneNumberLabel)}
      caption={
        hasPhoneNumber ? <PhoneNumber mobile={mobile?.value} phoneVerified={phoneVerified} /> : ''
      }
      action={
        <Button
          label={formatMessage(
            getActionMessageDescriptor({ verified: phoneVerified, phoneNumber: mobile?.value }),
          )}
          size="medium"
          hierarchy="tertiary"
          state={!isPhoneValidationEnabled ? 'disabled' : 'enabled'}
          onClick={handleActionPress}
          style={{ position: 'relative', right: '-16px' }}
        />
      }
    />
  );
}

type EmailItemProps = {
  email: Validations;
};

function EmailValue({ email }: EmailItemProps): JSX.Element {
  return (
    <VerifiableFIeld>
      {isValidated(email?.status) && (
        <VerifiedIcon>
          <FenixIcon token="circle-check" color="icon-color-tertiary" size="small" />
        </VerifiedIcon>
      )}
      {email?.value}
    </VerifiableFIeld>
  );
}

function EmailItem({ email }: EmailItemProps): JSX.Element {
  const { formatMessage } = useIntl();

  return (
    <ItemSection
      label={formatMessage(messages.emailLabel)}
      caption={<EmailValue email={email} />}
    />
  );
}

function EditPersonalInformation(): JSX.Element {
  const navigate = useNavigate();
  const { data: userValidations, isLoading: isLoadingUserValidations } = useUserValidations();
  const { isDesktop } = useDevice();
  const { formatMessage } = useIntl();

  const getValidation = (type: string) => {
    return userValidations?.validations.find((validation) => validation.type === type);
  };

  const handleGoBackPress = (): void => {
    navigate(-1);
  };

  React.useEffect(() => {
    if (!isDesktop) {
      document.body.style.backgroundColor = fenixTheme.color('shape-color-background-primary');
    }

    return () => {
      document.body.style.backgroundColor = null;
    };
  }, [isDesktop]);

  if (isLoadingUserValidations) {
    return (
      <Layout>
        <PageTitle title={formatMessage(messages.title)} onBack={handleGoBackPress} />
        {isDesktop ? <OverlayLoader /> : <PersonalInformationSkeleton />}
      </Layout>
    );
  }

  return (
    <Layout>
      <PageTitle title={formatMessage(messages.title)} onBack={handleGoBackPress} />
      <Card withBorder={false} withShadow={false} isDesktop={isDesktop}>
        <PersonalDataItem />
        <EditPhoneConfirmationProvider>
          <PhoneItem mobile={getValidation(PHONE)} />
        </EditPhoneConfirmationProvider>
        <EmailItem email={getValidation(EMAIL)} />
      </Card>
    </Layout>
  );
}

export { EditPersonalInformation };
