import { RoutePaths } from '@app/routes';
import { TrackingEvents, TrackingOrigins } from '@app/tracking';
import { Button } from '@pedidosya/web-fenix/atoms';
import { Modal } from '@pedidosya/web-fenix/organisms';
import OverlayLoader from '@components/OverlayLoader';
import { useIntl } from '@providers/IntlProvider';
import { useTracker } from '@providers/TrackerProvider';
import * as React from 'react';
import { defineMessages } from 'react-intl';
import { useNavigate } from 'react-router-dom';

const messages = defineMessages({
  cancel: {
    id: 'edit_phone_confirmation_modal.cancel',
    defaultMessage: 'Cancelar',
  },
  continue: {
    id: 'edit_phone_confirmation_modal.continue',
    defaultMessage: 'Editar',
  },
  description: {
    id: 'edit_phone_confirmation_modal.description',
    defaultMessage: 'Ten en cuenta que ya está validado.',
  },
  title: {
    id: 'edit_phone_confirmation_modal.title',
    defaultMessage: '¿Quieres editar tu celular?',
  },
});

enum TrackingActions {
  Continue = 'continue',
  No = 'no',
}

enum TrackingClickLocation {
  Button = 'button',
  Outside = 'outside',
}

enum TrackingTypes {
  EditValidatedPhone = 'edit_validated_phone',
}

type EditPhoneConfirmationModalProps = {
  isOpen: boolean;
  accept: () => void;
  close: () => void;
};

function EditPhoneConfirmationModal({
  isOpen,
  accept,
  close,
}: EditPhoneConfirmationModalProps): JSX.Element {
  const { formatMessage } = useIntl();
  const tracker = useTracker();
  const navigate = useNavigate();

  const handleContinuePress = async (): Promise<void> => {
    tracker.track(TrackingEvents.ModalClosed, {
      modalType: TrackingTypes.EditValidatedPhone,
      action: TrackingActions.Continue,
      clickLocation: TrackingClickLocation.Button,
    });
    accept();
    navigate(`${RoutePaths.PhoneValidation}?origin=${TrackingOrigins.phoneEdit}`);
  };

  const handleCancelPress = (): void => {
    tracker.track(TrackingEvents.ModalClosed, {
      modalType: TrackingTypes.EditValidatedPhone,
      action: TrackingActions.No,
      clickLocation: TrackingClickLocation.Button,
    });
    close();
  };

  const handleClose = (): void => {
    tracker.track(TrackingEvents.ModalClosed, {
      modalType: TrackingTypes.EditValidatedPhone,
      action: TrackingActions.No,
      clickLocation: TrackingClickLocation.Outside,
    });
    close();
  };

  React.useEffect(() => {
    if (isOpen) {
      tracker.track(TrackingEvents.ModalLoaded, {
        modalType: TrackingTypes.EditValidatedPhone,
      });
    }
  }, [isOpen, tracker]);

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      title={formatMessage(messages.title)}
      text={formatMessage(messages.description)}
      primaryButton={
        <Button
          fullWidth
          label={formatMessage(messages.continue)}
          size="large"
          onClick={handleContinuePress}
        />
      }
      secondaryButton={
        <Button
          fullWidth
          hierarchy="secondary"
          label={formatMessage(messages.cancel)}
          size="large"
          onClick={handleCancelPress}
        />
      }
    />
  );
}

type EditPhoneConfirmationContextProps = {
  open: () => void;
};

const EditPhoneConfirmationContext = React.createContext<EditPhoneConfirmationContextProps>(
  {} as EditPhoneConfirmationContextProps,
);
EditPhoneConfirmationContext.displayName = 'EditPhoneConfirmationContext';

type EditPhoneConfirmationProviderProps = {
  children?: React.ReactElement;
};

function EditPhoneConfirmationProvider({
  children,
}: EditPhoneConfirmationProviderProps): JSX.Element {
  const [isOpen, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  function open(): void {
    setOpen(true);
  }

  function close(): void {
    setOpen(false);
  }

  function accept(): void {
    close();
    setIsLoading(true);
  }

  return (
    <EditPhoneConfirmationContext.Provider value={{ open }}>
      {children}
      {isLoading && <OverlayLoader />}
      <EditPhoneConfirmationModal isOpen={isOpen} close={close} accept={accept} />
    </EditPhoneConfirmationContext.Provider>
  );
}

function useEditPhoneConfirmationModal(): EditPhoneConfirmationContextProps {
  const context = React.useContext(EditPhoneConfirmationContext);
  if (context === undefined) {
    throw new Error(
      'useEditPhoneConfirmationModal must be used within a EditPhoneConfirmationProvider',
    );
  }

  return context;
}

export { EditPhoneConfirmationProvider, useEditPhoneConfirmationModal };
